import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import ProductCard from './ProductCard';

import tshirtImage from '../assets/Products/blank-white-t-shirt-front-view.png';
import hoodieImage from '../assets/Products/hoodie.png';
import mugImage from '../assets/Products/mug.png';
import stickersImage from '../assets/Products/Stickers.jpg';
import sweatshirtImage from '../assets/Products/sweatshirt.png';
import toteBagImage from '../assets/Products/totebag.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductsCarousel = () => {
    const products = [
        {
            name: 'T-Shirt',
            description: 'A comfortable and versatile blank white t-shirt made from high-quality fabric.',
            image: tshirtImage,
        },
        {
            name: 'Hoodie',
            description: 'Stay warm and stylish with our premium hoodie designed for ultimate comfort.',
            image: hoodieImage,
        },
        {
            name: 'Mug',
            description: 'Start your day with a beautiful mug that holds your favorite hot or cold beverage.',
            image: mugImage,
        },
        {
            name: 'Stickers',
            description: 'Add a touch of personality to your belongings with our cool and durable stickers.',
            image: stickersImage,
        },
        {
            name: 'Sweatshirt',
            description: 'Experience coziness like never before with our soft and snug sweatshirt.',
            image: sweatshirtImage,
        },
        {
            name: 'Tote Bag',
            description: 'Carry your essentials in style with our practical and eco-friendly tote bag.',
            image: toteBagImage,
        },
    ];

    const sliderRef = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            const interval = setInterval(() => {
                sliderRef.current.slickNext();
            }, 2000);
            return () => clearInterval(interval);
        }
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const handleMouseEnter = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPause();
        }
    };

    const handleMouseLeave = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPlay();
        }
    };

    return (
        <div id="products" className="max-w-[1240px] mx-auto py-8 px-4">
            <h2 className="text-3xl font-bold mb-8">Featured Products</h2>
            <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Slider {...settings} ref={sliderRef}>
                    {products.map((product, index) => (
                        <div key={index} className="px-2">
                            <ProductCard {...product} />
                        </div>
                    ))}
                </Slider>
                <div className="absolute top-1/2 transform -translate-y-1/2 flex items-center justify-between w-full px-4">
                    <button
                        className="text-white bg-red-700 rounded-full p-2 focus:outline-none hover:bg-red-800 transition duration-300"
                        onClick={() => sliderRef.current?.slickPrev()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <button
                        className="text-white bg-red-700 rounded-full p-2 focus:outline-none hover:bg-red-800 transition duration-300"
                        onClick={() => sliderRef.current?.slickNext()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductsCarousel;
