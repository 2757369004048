import React from 'react';
import { Link } from 'react-scroll';

export const Footer = () => {
  return (
      <footer className="bg-white text-gray-700 mt-20 py-12 max-w-[1240px] mx-auto border-t-2 border-red-500">
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center md:text-left">
              <h5 className="uppercase mb-2 font-bold">Contact Us</h5>
              <p className="mb-4">
                Oued Smar, Alger 16000
                <br />
                Algeria
              </p>
              <a href = "mailto: contact@perso-pro.com">contact@perso-pro.com</a>
            </div>
            <div className="text-center md:text-left">
              <h5 className="uppercase mb-2 font-bold">Links</h5>
              <ul className="mb-4">
                <li className="mt-2">
                  <Link to="navbar" smooth={true} duration={1000} className="hover:text-red-500">
                    Home
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="about" smooth={true} duration={500} className="hover:text-red-500">
                    Contact Us
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="about" smooth={true} duration={500} className="hover:text-red-500">
                    Register Now
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-center md:text-left">
              <h5 className="uppercase mb-2 font-bold">Social Media</h5>
              <ul className="mb-4">
                <li className="mt-2">
                  <a href="https://www.facebook.com/people/PersoPro/100092586087313/" className="hover:text-red-500">
                    Facebook
                  </a>
                </li>
                <li className="mt-2">
                  <a href="https://www.linkedin.com/company/persopro/" className="hover:text-red-500">
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center">
            <p>&copy; 2023 persopro. All rights reserved.</p>
          </div>
        </div>
      </footer>
  );
};
