import React from "react";
import { FaPalette, FaPrint, FaBoxOpen, FaTruck } from "react-icons/fa";

const Services = () => {
    const services = [
        {
            title: "Design Creation",
            description:
                "Assistance with designing or customizing products, including the ability to upload personal artwork or use design templates.",
            icon: <FaPalette size={40} />,
        },
        {
            title: "Printing",
            description: "High-quality printing services using various printing techniques.",
            icon: <FaPrint size={40} />,
        },
        {
            title: "Packaging",
            description: "Proper packaging of products to ensure they are protected during shipping and arrive in excellent condition.",
            icon: <FaBoxOpen size={40} />,
        },
        {
            title: "Shipping",
            description: "Reliable shipping services.",
            icon: <FaTruck size={40} />,
        },
    ];

    return (
        <section id="services" className="bg-[#fbfbfa] py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Our Services</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {services.map((service, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="bg-white rounded-full p-4 mb-4">
                                {service.icon}
                            </div>
                            <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                            <p className="text-gray-700 text-center">{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Services;
