import React, { useState } from "react";
import axios from "axios";
import { TiTick } from "react-icons/ti";

const Newsletter = () => {
    const [email, setEmail] = useState("");
    const [subscribed, setSubscribed] = useState(false);
    const [error, setError] = useState("");

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "https://api.sendinblue.com/v3/contacts",
                {
                    email: email,
                    listIds: [2], // Replace with your SendinBlue list ID
                    updateEnabled: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "api-key": "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", // Replace with your SendinBlue API key
                    },
                }
            );

            if (response.data.id) {
                setSubscribed(true);
                setEmail("");
                setError("");
            } else {
                setError("An error occurred. Please try again later.");
            }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message === "Contact already exists"
            ) {
                setError("You are already subscribed to our newsletter.");
            } else {
                setError("An error occurred. Please try again later.");
            }
        }
    };

    return (
        <section id="about" className="bg-red-500 py-12">
            <div className="container mx-auto px-4">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl text-white font-bold mb-4">
                        Subscribe to Our Newsletter
                    </h2>
                    <p className="text-white mb-6">
                        Stay up-to-date with the latest news and promotions.
                    </p>
                    {subscribed ? (
                        <div className="flex items-center justify-center mb-6">
                            <TiTick className="text-green-500 text-2xl mr-2" />
                            <p className="text-white">Thank you for subscribing!</p>
                        </div>
                    ) : (
                        <form
                            className="flex flex-col md:flex-row items-center justify-center max-w-lg mx-auto"
                            onSubmit={handleSubmit}
                        >
                            <input
                                type="email"
                                className="bg-white border border-gray-400 rounded-l-lg py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent mb-4 md:mb-0 md:mr-2"
                                placeholder="Enter your email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <button
                                type="submit"
                                className="bg-white text-red-500 font-bold py-3 px-6 rounded-r-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:bg-gray-200"
                            >
                                Subscribe
                            </button>
                        </form>
                    )}
                    {error && !subscribed && (
                        <p className="text-red-500 bg-red-100 py-2 px-4 rounded mt-4">
                            {error}
                        </p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Newsletter;
