import React from 'react';

const ProductCard = ({name, description, image}) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center">
            <img src={image} alt={name} className="mx-auto mb-4" style={{maxWidth: '200px'}}/>
            <h3 className="text-2xl font-bold mb-2 text-center">{name}</h3>
            <p className="text-gray-600 leading-relaxed mb-4 text-center">{description}</p>
        </div>
    );
};

export default ProductCard;
