import ContactForm from "./components/ContactForm";
import { Footer } from "./components/Footer";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import Newsletter from "./components/Newsletter";
import ProductsCarousel from "./components/ProductsCarousel";
import Services from "./components/Services";

function App() {
  return (
    <div className="App">
        <NavBar />
        <Hero />
        <ProductsCarousel />
        <Services />
        <ContactForm />
        <Newsletter />
        <Footer />
    </div>
  );
}

export default App;
