import React from 'react';
import soon from '../assets/soon.png';
import PlayStore from '../assets/playStore.png';
import { Link, Element } from 'react-scroll';

const Hero = () => {
  return (
      <div id="home" className="hero md:h-screen w-full bg-gradient-to-b from-white to-red-50 py-20">
        <section className="flex flex-col md:flex-row items-center max-w-[1240px] mx-auto md:justify-between px-8 py-4">
          <div className="md:w-2/5">
            <img className="h-[500px] max-w-lg mx-auto" src={soon} alt="Hero" />
          </div>
          <div className="md:w-3/5">
            <div className="flex flex-col justify-center items-start h-full md:pl-12">
              <h3 className="font-bold text-2xl md:text-3xl text-red-600 transition duration-500">
                persopro, Design Your World
              </h3>
              <h1 className="font-bold text-3xl md:text-5xl transition duration-500 my-2">
                Find ALL your event's goodies in ONE place!
              </h1>
              <p className="text-gray-700 pb-4 md:text-xl transition duration-500 max-w-2xl text-justify">
                We are here to facilitate your event's goodies preparation. Order all your products from just ONE place.
                Search, get quotes, compare to have the best price & quality, make your order... !!! You can do ALL these
                steps without leaving your desk in 10 minutes with persopro.
              </p>
              <Link to="about" smooth={true} duration={1000}>
                <button className="bg-red-500 hover:scale-110 text-white font-bold py-2 px-4 rounded">
                  Subscribe to our newsletter
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>
  );
};

export default Hero;
