import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../assets/logo/logo.png";
import { Link } from "react-scroll";

const NavBar = () => {
    const [nav, setNav] = useState(true);

    const handleMenuClick = () => {
        setNav(!nav);
    };

    const MenuData = [
        {
            title: "Home",
            url: "#home",
            cName: "nav-links",
        },
        {
            title: "Products",
            url: "#products",
            cName: "nav-links",
        },
        {
            title: "Services",
            url: "#services",
            cName: "nav-links",
        },
        {
            title: "About",
            url: "#about",
            cName: "nav-links",
        },
    ];

    return (
        <header id="navbar" className="flex justify-between items-center h-20 max-w-[1240px] mx-auto px-4 border-b-2 rounded-md">
            <img src={logo} alt="Logo persopro" className="w-[140px] my-auto" />

            <nav className="hidden md:flex justify-center items-center">
                {MenuData.map(({ title, url, cName }, index) => (
                    <Link
                        key={index}
                        to={url.substring(1)}
                        className="font-bold text-gray-600 capitalize px-4 cursor-pointer transition duration-200 ease-in-out hover:bg-[#ce1c1c] hover:text-white rounded-md"
                        smooth={true}
                        duration={1000}
                        onClick={() => setNav(true)}
                    >
                        {title}
                    </Link>
                ))}
            </nav>

            <div className="hidden md:flex">
                <button className="p-2 bg-[#ce1c1c] w-[200px] rounded-md font-medium mx-auto my-2 text-white hover:scale-105">
                    Download The App
                </button>
            </div>

            <div onClick={handleMenuClick} className="block md:hidden text-black">
                {nav ? (
                    <AiOutlineMenu color="#CF1D1C" size={20} />
                ) : (
                    <AiOutlineClose color="#CF1D1C" size={20} />
                )}
            </div>

            <div
                className={
                    !nav
                        ? "md:hidden fixed left-0 top-0 h-full w-[90%] bg-[#fbfbfa] ease-in-out duration-500 text-white"
                        : "md:hidden fixed left-[-200%] top-0 h-full w-[50%] ease-in-out duration-500"
                }
            >
                <div className="flex flex-col items-center justify-center border-red-700">
                    <img src={logo} alt="Logo persopro" className="w-[140px] mx-auto my-6" />

                    <nav className="flex flex-col justify-center items-center">
                        {MenuData.map(({ title, url, cName }, index) => (
                            <Link
                                key={index}
                                to={url.substring(1)}
                                className="font-bold text-gray-600 capitalize p-4 cursor-pointer transition duration-200 ease-in-out hover:bg-[#ce1c1c] hover:text-white rounded-md"
                                smooth={true}
                                duration={500}
                                onClick={() => setNav(true)}
                            >
                                {title}
                            </Link>
                        ))}
                    </nav>

                    <div>
                        <button className="p-2 bg-[#ce1c1c] w-[200px] rounded-md font-medium mx-auto my-2 text-white">
                            Download The App
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NavBar;
