import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://formspree.io/f/xjvddaqn", {
        name,
        email,
        message,
      });

      setName("");
      setEmail("");
      setMessage("");
      setSuccess(true);
      setError("");
    } catch (error) {
      setError("An error occurred while sending the message.");
    }
  };

  return (
      <section className="bg-red-50 py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-2xl mx-auto">
            <h2 className="text-3xl text-center font-bold mb-4 text-red-600">
              Contact Us
            </h2>
            {success ? (
                <div className="text-center">
                  <svg
                      className="w-12 h-12 mx-auto mb-4 text-green-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p className="text-green-500">Message sent successfully!</p>
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
                >
                  <div className="mb-4">
                    <label
                        htmlFor="name"
                        className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        placeholder="Enter your name"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-red-500"
                        value={name}
                        onChange={handleNameChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-red-500"
                        value={email}
                        onChange={handleEmailChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                        htmlFor="message"
                        className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Message
                    </label>
                    <textarea
                        id="message"
                        rows="5"
                        placeholder="Enter your message"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-red-500"
                        value={message}
                        onChange={handleMessageChange}
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button
                        type="submit"
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
                    >
                      Send Message
                    </button>
                  </div>
                  {error && <p className="text-red-500">{error}</p>}
                </form>
            )}
          </div>
        </div>
      </section>
  );
};

export default ContactForm;
